import { defineStore } from "pinia";
import { useMainStore } from '@/store/main';
import { CompanyType } from "@/types/company";
import { FarmType } from "@/types/farm";
import { BarnType } from "@/types/barn";
import { SensorType } from "@/types/sensor";
import { BarnVisibilityType } from "@/types/barn_visibility";
import { API_URL } from "@/utils/urls";

const MAIN_STORE = useMainStore()


export const useDashboardStore = defineStore("dashboardStore", {
  state: () => ({
    companies: [] as CompanyType[],
    visibility: 'hide_disabled' as BarnVisibilityType,
  }),
  actions: {
    async setHomeInfo(): Promise<void> {
      try {
        this.companies = await MAIN_STORE.getFromAPI(`${API_URL}/companies/support_platform_report`)
      }
      catch(error) {throw Error(`${error}`.replace("Error: ", ""))}
    },
    setVisibility(value: BarnVisibilityType): void {
      this.visibility = value
    },
  },
  getters: {
    getVisibility: state => state.visibility,
    getCompanies: state => state.companies,
    getFarms: state => {
      const farms: FarmType[] = []
      state.companies.forEach((company: CompanyType) => {farms.push(...company.farms)})
      return farms.sort((a, b) => a.name.localeCompare(b.name))
    },
    getBarns() {
      const barns: BarnType[] = []
      this.getFarms.forEach((farm: FarmType) => {barns.push(...farm.barns)})
      return barns
    },
    getAllSensors() {
      const sensors: SensorType[] = []
      this.getBarns.forEach((barn: BarnType) => {sensors.push(...barn.sensors)})
      return sensors
    },
    getActiveSensors() {
      const sensors: SensorType[] = []
      this.getBarns.forEach((barn: BarnType) => {
        if (this.getVisibility === 'hide_disabled') {
          if (barn.is_last_batch_active) {
            sensors.push(...barn.sensors)
          }
        }
        else {
          sensors.push(...barn.sensors)
        }
      })
      return sensors
    },
    getSensorsInstalled(): SensorType[] {
      return this.getActiveSensors.filter((sensor: SensorType) => sensor.status === 'installed')
    },
    getSmartCamSensors(): SensorType[] {
      return this.getSensorsInstalled.filter((item: SensorType) => item.type === 'smartcam')
    },
    getSmartMicSensors(): SensorType[] { 
      return this.getSensorsInstalled.filter((item: SensorType) => {
        return item.type.includes('smartmic')
      })
    },
    getSmartFeedSensors(): SensorType[] {
      return this.getSensorsInstalled.filter((item: SensorType) => item.type === 'smartfeed')
    },
    getActiveSmartFeedSensors(): SensorType[] {
      const sensors: SensorType[] = []
      this.getBarns.forEach((barn: BarnType) => {sensors.push(...barn.sensors)})
      return sensors.filter((sensor: SensorType) => sensor.type === 'smartfeed' && sensor.status !== 'disabled')
    },
    getFarmByBarn() {
      return (barn_pk: number | undefined): FarmType | null | undefined => {
        if (barn_pk === undefined) return null;
        return this.getFarms.find((farm: FarmType) => farm.barns.map((barn: BarnType) => barn.pk).includes(barn_pk) ? farm : null)
      }
    },
    getBarnByPK() {
      return (barn_pk: number | undefined): BarnType | null => {
        const barn = this.getBarns.find((barn: BarnType) => barn_pk === barn.pk)
        if (barn_pk == null || barn == null) return null
        return barn
      }
    },
  },
});
